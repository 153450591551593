import Vue from 'vue';
import VueRouter, { NavigationGuardNext, RouteConfig, Route } from 'vue-router';
import topbar from 'topbar';

import store from '@/store/';

import Account from '@/services/Account/AccountService';

import ActiveModal from '@/share/Util/ActiveModal';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import Dashboard from '../pages/Dashboard/Dashboard.vue';

import MiddlewareSimulated from './Middleware/CheckUserStatusInSimulated';
import { permissionPlanCoursePageForContext } from './Middleware/PlanCourse';
import { multiGuard } from './Middleware/MultiGuard';
import { permissionPageExam } from './Middleware/permission/PageExam';
import { permissionPlanner, permissionPlannerContext } from './Middleware/permission/Planner';
import { permissionCourse, permissionCoursePage } from './Middleware/permission/Course';
import {
  permissionRedaction,
  permissionRedactionCredits,
  verifyOptionsSendRedaction,
  permissionRedactionFeature,
} from './Middleware/permission/Redaction';
import { permissionDoubts, permissionDoubtsCredits, permissionDoubtsFeature } from './Middleware/permission/Doubts';
import { permissionSimulated, permissionSimulator } from './Middleware/permission/Simulated';
import {
  onboardingRedaction,
  onboardingCourse,
  onboardingQuestions,
  onboardingDoubts,
  onboardingBooks,
  onboardingVideos,
  onboardingExercise,
  onboardingSimulated,
  onboardingExam,
  onboardingPlanner,
} from './Middleware/onboarding/index';
import { permissionBookFeature } from './Middleware/permission/Book';
import { permissionFeature } from './Middleware/permission/Feature';
import { permissionAds } from './Middleware/permission/Ads';
import { permissionQuestion } from './Middleware/permission/Question';
import { permissionShopping } from './Middleware/permission/Shopping';
import { permissionVideo } from './Middleware/permission/Videos';
import { permissionArchive } from './Middleware/permission/Material';
import { permissionExercise } from './Middleware/permission/Exercise';

import CookieUtil from '../services/Util/cookieUtil';

import { logout } from '@/share/Util/Logout/index';
import { updateProfile } from '@/share/Util/Profile/Profile';
import { removeIsFreeQueryFromUrl } from '@/share/Util/Route';
import { assessBusinessModelForTheme } from '@/share/Util/Color/ConfigureColors';

topbar.config({
  barColors: {
    0: 'rgba(255, 221, 0, 1)',
    '.3': 'rgba(255, 221, 0, 1)',
    '1.0': 'rgba(255, 221, 0, 1)',
  },
});

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'begin',
    redirect: '/dashboard',
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/NotFound/NotFound.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/Profile/Profile.vue'),
    meta: { title: 'Profile' },
  },
  {
    path: '/historico',
    name: 'HistoricPayment',
    component: () => import(/* webpackChunkName: "HistoricPayment" */ '../pages/HistoricPayment/HistoricPayment.vue'),
    meta: { title: 'Histórico de pagamentos' },
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import(/* webpackChunkName: "Ranking" */ '../pages/Ranking/Ranking.vue'),
    meta: { title: 'Ranking' },
  },
  {
    path: '/planner',
    name: 'Planner',
    component: () => import(/* webpackChunkName: "planner" */ '../pages/Planner/Planner.vue'),
    meta: { title: 'Plano de estudos' },
    beforeEnter: multiGuard([
      permissionFeature,
      onboardingPlanner,
    ]),
  },
  {
    path: '/planner/tutorial',
    name: 'TutorialPlanner',
    component: () => import(/* webpackChunkName: "planner-tutorial" */ '../pages/Planner/pages/TutorialPlanner/index.vue'),
    meta: { title: 'Tutorial Planner' },
    beforeEnter: permissionFeature,
  },
  {
    path: '/planner/configuracao',
    name: 'PlannerConfig',
    component: () => import(/* webpackChunkName: "planner-config" */ '../pages/Planner/pages/PlannerConfig/PlannerConfig.vue'),
    meta: { title: 'Configuração do Plano de estudos' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionPlanner,
    ]),
  },
  {
    path: '/cadernos',
    name: 'Books',
    component: () => import(/* webpackChunkName: "book" */ '../pages/Books/index.vue'),
    meta: { title: 'Cadernos' },
    beforeEnter: onboardingBooks,
  },
  {
    path: '/caderno/:path',
    name: 'BooksView',
    component: () => import(/* webpackChunkName: "book-show" */ '../pages/Book/index.vue'),
    meta: { title: 'Caderno', breadcrumbs: 'Caderno/:path' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      onboardingBooks,
    ]),
  },
  {
    path: '/cadernos/:path?/tutorial',
    name: 'TutorialBooks',
    component: () => import(/* webpackChunkName: "books-tutorial" */ '../pages/Books/pages/TutorialBooks/index.vue'),
    meta: { title: 'Tutorial Cadernos' },
  },
  {
    path: '/videos/:context/:path/:topicID/:fileID/:slugFile',
    name: 'videos',
    component: () => import(/* webpackChunkName: "video" */ '../pages/Video/Video.vue'),
    meta: { title: 'Vídeos' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      onboardingVideos,
      permissionVideo,
    ]),
  },
  {
    path: '/videos/:context/:path/:topicID/:fileID/:slugFile/tutorial',
    name: 'TutorialVideos',
    component: () => import(/* webpackChunkName: "videos-tutorial" */ '../pages/Video/pages/TutorialVideos/index.vue'),
    meta: { title: 'Tutorial Vídeos' },
  },
  {
    path: '/materiais/:context/:path/:topicID/:fileID/:slugFile',
    name: 'materiais',
    component: () => import(/* webpackChunkName: "materials" */ '../pages/Materials/Materials.vue'),
    meta: { title: 'Materiais' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionPlanCoursePageForContext,
      permissionArchive,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/resultado/desempenho',
    name: 'ExerciseResultPerformance',
    component: () => import(/* webpackChunkName: "exercise-performance" */ '../pages/Exercise/pages/ExerciseResultPerformance/ExerciseResultPerformance.vue'),
    meta: { title: 'Desempenho' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/resultado',
    name: 'ExerciseResult',
    component: () => import(/* webpackChunkName: "exercise-result" */ '../pages/Exercise/pages/ExerciseResult/ExerciseResult.vue'),
    meta: { title: 'Resultado' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/questoes',
    name: 'ExerciseQuestions',
    component: () => import(/* webpackChunkName: "exercise-question" */ '../pages/Exercise/pages/ExerciseQuestions/ExerciseQuestions.vue'),
    meta: { title: 'Questões' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile',
    name: 'exercicio',
    component: () => import(/* webpackChunkName: "exercise" */ '../pages/Exercise/Exercise.vue'),
    meta: { title: 'Exercícios' },
    beforeEnter: multiGuard([
      permissionBookFeature,
      permissionCourse,
      permissionPlannerContext,
      onboardingExercise,
      permissionExercise,
    ]),
  },
  {
    path: '/exercicios/:context/:path/:topicID/:fileID/:slugFile/tutorial',
    name: 'TutorialExercise',
    component: () => import(/* webpackChunkName: "exercise-tutorial" */ '../pages/Exercise/pages/TutorialExercise/index.vue'),
    meta: { title: 'Tutorial Exercícios' },
  },
  {
    path: '/cursos',
    name: 'CoursesList',
    component: () => import(/* webpackChunkName: "course-list" */ '../pages/Courses/pages/ListCourses/ListCourses.vue'),
    meta: { title: 'Cursos' },
    beforeEnter: multiGuard([
      permissionFeature,
      onboardingCourse,
    ]),
  },
  {
    path: '/cursos/tutorial/:id?',
    name: 'TutorialCourse',
    component: () => import(/* webpackChunkName: "redaction-tutorial" */ '../pages/Courses/pages/TutorialCourse/TutorialCourse.vue'),
    meta: { title: 'Tutorial Cursos' },
    beforeEnter: multiGuard([
      permissionFeature,
      // permissionCoursePage,
    ]),
  },
  {
    path: '/cursos/:slugCourse/:idCourses/:idClass',
    name: 'Courses',
    component: () => import(/* webpackChunkName: "course" */ '../pages/Courses/Courses.vue'),
    meta: { title: 'Cronograma do Curso' },
    beforeEnter: multiGuard([
      permissionFeature,
      // permissionCoursePage,
      onboardingCourse,
    ]),
  },
  {
    path: '/duvidas',
    name: 'Doubts',
    component: () => import(/* webpackChunkName: "doubts" */ '../pages/Doubts/Doubts.vue'),
    meta: { title: 'Dúvidas' },
    beforeEnter: multiGuard([
      // permissionFeature,
      // permissionDoubtsFeature,
      onboardingDoubts,
    ]),
  },
  {
    path: '/duvidas/tutorial',
    name: 'TutorialDoubts',
    component: () => import(/* webpackChunkName: "doubts-tutorial" */ '../pages/Doubts/pages/TutorialDoubts/TutorialDoubts.vue'),
    meta: { title: 'Tutorial Dúvidas' },
    // beforeEnter: multiGuard([
    //   permissionFeature,
    //   permissionDoubts,
    // ]),
  },
  {
    path: '/duvida/:id',
    name: 'ShowDoubts',
    component: () => import(/* webpackChunkName: "doubts-show" */ '../pages/Doubts/pages/ShowDoubts/ShowDoubts.vue'),
    meta: { title: 'Visualização da Dúvida' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionDoubts,
      onboardingDoubts,
    ]),
  },
  {
    path: '/enviar_duvida',
    name: 'NewDoubt',
    component: () => import(/* webpackChunkName: "doubts-send" */ '../pages/Doubts/pages/NewDoubt/NewDoubt.vue'),
    meta: { title: 'Enviar Dúvida' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionDoubtsCredits,
      onboardingDoubts,
    ]),
  },
  {
    path: '/vestibular',
    name: 'Exams',
    component: () => import(/* webpackChunkName: "question" */ '../pages/Exams/index.vue'),
    meta: { title: 'Vestibulares' },
    beforeEnter: multiGuard([
      // permissionFeature,
      onboardingExam,
    ]),
  },
  {
    path: '/vestibular/tutorial',
    name: 'TutorialExam',
    component: () => import(/* webpackChunkName: "exam-tutorial" */ '../pages/Exams/pages/TutorialExam/index.vue'),
    meta: { title: 'Tutorial Vestibulares' },
    // beforeEnter: permissionFeature,
  },
  {
    path: '/vestibular/:book/:idExam',
    name: 'Exam',
    component: () => import(/* webpackChunkName: "question-show" */ '../pages/Exams/pages/Exam/index.vue'),
    meta: { title: 'Vestibular Questões' },
    beforeEnter: multiGuard([
      // permissionFeature,
      // permissionPageExam,
    ]),
  },
  {
    path: '/vestibular/pdf/:idExam/:idFile/:slug',
    name: 'ExamViewer',
    component: () => import(/* webpackChunkName: "question-show" */ '../pages/Exams/pages/ExamViewer/index.vue'),
    meta: { title: 'Visualizador Vestibular' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionPageExam,
    ]),
  },
  {
    path: '/redacao',
    name: 'Redaction',
    component: () => import(/* webpackChunkName: "redaction" */ '../pages/Redaction/Redaction.vue'),
    meta: { title: 'Redação' },
    beforeEnter: multiGuard([
      // permissionFeature,
      permissionRedactionFeature,
      onboardingRedaction,
    ]),
  },
  {
    path: '/redacao/tutorial',
    name: 'TutorialRedaction',
    component: () => import(/* webpackChunkName: "redaction-tutorial" */ '../pages/Redaction/pages/TutorialRedaction/TutorialRedaction.vue'),
    meta: { title: 'Tutorial Redação' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedaction,
    ]),
  },
  {
    path: '/redacao/:themeId/instrucoes',
    name: 'RedactionInstruction',
    component: () => import(/* webpackChunkName: "redaction-instruction" */ '../pages/Redaction/pages/InstructionRedaction/InstructionRedaction.vue'),
    meta: { title: 'Redação Instruções' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/redacao/minhas/redacoes',
    name: 'MyRedactions',
    component: () => import(/* webpackChunkName: "redaction-me" */ '../pages/Redaction/pages/MyRedactions/MyRedactions.vue'),
    meta: { title: 'Minhas Redações' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/redacao/:redactionId/correcao',
    name: 'CorrectionRedaction',
    component: () => import(/* webpackChunkName: "redaction-correction" */ '../pages/Redaction/pages/CorrectionRedaction/CorrectionRedaction.vue'),
    meta: { title: 'Correção Redação' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/redacao/:themeId/enviar',
    name: 'RedactionSend',
    component: () => import(/* webpackChunkName: "redaction-send" */ '../pages/Redaction/pages/SendRedaction/SendRedaction.vue'),
    meta: { title: 'Redação Envio' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
      verifyOptionsSendRedaction,
    ]),
  },
  {
    path: '/redacao/:redactionId/nota_mil',
    name: 'NoteAThousandRedaction',
    component: () => import(/* webpackChunkName: "redaction-note" */ '../pages/Redaction/pages/NoteAThousandRedaction/NoteAThousandRedaction.vue'),
    meta: { title: 'Redação Nota Mil' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionRedactionCredits,
    ]),
  },
  {
    path: '/atualizar_plano/:nameBreadcrumb',
    name: 'FeedbackPermission',
    component: () => import(/* webpackChunkName: "updated-plan" */ '../pages/FeedbackPermission/FeedbackPermission.vue'),
    meta: { title: 'Atualizar plano' },
    beforeEnter: permissionAds,
  },
  {
    path: '/simulados',
    name: 'SimulationsList',
    component: () => import(/* webpackChunkName: "simulated-list" */ '../pages/Simulated/pages/ListSimulations/ListSimulations.vue'),
    meta: { title: 'Simulados' },
    beforeEnter: multiGuard([
      permissionFeature,
      onboardingSimulated,
    ]),
  },
  {
    path: '/simulados/tutorial',
    name: 'TutorialSimulation',
    component: () => import(/* webpackChunkName: "simulated-tutorial" */ '../pages/Simulated/pages/TutorialSimulated/index.vue'),
    meta: { title: 'Tutorial Simulado' },
    beforeEnter: permissionFeature,
  },

  {
    path: '/simulado/:id',
    name: 'SimulatedInstruction',
    component: () => import(/* webpackChunkName: "simulated-instruction" */ '../pages/Simulated/Simulated.vue'),
    meta: { title: 'Simulado' },
    beforeEnter: multiGuard([
      permissionFeature,
      // permissionSimulated,
      MiddlewareSimulated.beforeEnterInstruction,
    ]),
  },
  {
    path: '/simulado/:id/lista/:url',
    name: 'OtherSimulated',
    component: () => import(/* webpackChunkName: "simulated" */ '../pages/Simulated/pages/OtherSimulated/OtherSimulated.vue'),
    meta: { title: 'Vestibulares Anteriores' },
    beforeEnter: multiGuard([
      permissionFeature,
      // permissionSimulated,
    ]),
  },
  {
    path: '/simulado/:id/questions',
    name: 'SimulatedQuestions',
    component: () => import(/* webpackChunkName: "simulated-question" */ '../pages/Simulated/pages/SimulatedQuestion/SimulatedQuestion.vue'),
    meta: { title: 'Simulado Questões' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionSimulated,
      MiddlewareSimulated.beforeEnterQuestions,
    ]),
  },
  {
    path: '/simulado/:id/resultado',
    name: 'SimulatedResult',
    component: () => import(/* webpackChunkName: "simulated-result" */ '../pages/Simulated/pages/SimulatedResult/SimulatedResult.vue'),
    meta: { title: 'Resultado do Simulado' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionSimulated,
      MiddlewareSimulated.beforeEnterResult,
    ]),
  },
  {
    path: '/simulador/:id?/resultado/simulador_enem',
    name: 'EnemSimulator',
    component: () => import(/* webpackChunkName: "simulator" */ '../pages/Simulated/pages/EnemSimulator/EnemSimulator.vue'),
    meta: { title: 'Simulador Enem' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionSimulator,
    ]),
  },
  {
    path: '/simulado/:id?/resultado/simulador',
    name: 'SimulatorResult',
    component: () => import(/* webpackChunkName: "simulator-result" */ '../pages/Simulated/pages/SimulatorResult/SimulatorResult.vue'),
    meta: { title: 'Resultado da simulação do SISU' },
    beforeEnter: multiGuard([
      permissionFeature,
      permissionSimulator,
    ]),
  },
  {
    path: '/questoes',
    name: 'ListQuestions',
    component: () => import(/* webpackChunkName: "questions" */ '../pages/Question/Question.vue'),
    meta: { title: 'Lista de Questões' },
    beforeEnter: multiGuard([
      onboardingQuestions,
    ]),
  },
  {
    path: '/questoes/tutorial',
    name: 'TutorialQuestions',
    component: () => import(/* webpackChunkName: "questions" */ '../pages/Question/pages/TutorialQuestions/TutorialQuestions.vue'),
    meta: { title: 'Tutorial Questões' },
  },
  {
    path: '/questao/:id',
    name: 'AnswerQuestion',
    component: () => import(/* webpackChunkName: "questions" */ '../pages/Question/pages/AnswerQuestion/AnswerQuestion.vue'),
    meta: { title: 'Responder Questão' },
    beforeEnter: permissionQuestion,
  },
  {
    path: '/manutencao',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ '../pages/Maintenance/Maintenance.vue'),
    meta: { title: 'Manutenção', isAnonymous: true },
  },
  {
    path: '/problema',
    name: 'Problem',
    component: () => import(/* webpackChunkName: "problem" */ '../pages/Problem/Problem.vue'),
    meta: { title: 'Problema' },
  },
  {
    path: '/instabilidade',
    name: 'Instability',
    component: () => import(/* webpackChunkName: "instability" */ '../pages/Instability/Instability.vue'),
    meta: { title: 'Instabilidade' },
  },
  {
    path: '/loja',
    name: 'Store',
    component: () => import(/* webpackChunkName: "store" */ '../pages/Shopping/Shopping.vue'),
    meta: { title: 'Loja', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/pendencias',
    name: 'Pendencies',
    component: () => import(/* webpackChunkName: "pendencies" */ '../pages/Shopping/pages/PendencyDetail/PendencyDetail.vue'),
    meta: { title: 'Pendências' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/descritivo',
    name: 'Descriptive',
    component: () => import(/* webpackChunkName: "descriptive" */ '../pages/Shopping/pages/Descriptive/index.vue'),
    meta: { title: 'Descritivo', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:discount?',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "payment" */ '../pages/Shopping/pages/Payment/Payment.vue'),
    meta: { title: 'Pagamento', isAnonymous: true },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/boleto',
    name: 'PaymentBillet',
    component: () => import(/* webpackChunkName: "payment-billet" */ '../pages/Shopping/pages/PaymentBillet/PaymentBillet.vue'),
    meta: { title: 'Pagamento com boleto' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/pix',
    name: 'PaymentPix',
    component: () => import(/* webpackChunkName: "payment-pix" */ '../pages/Shopping/pages/PaymentPix/PaymentPix.vue'),
    meta: { title: 'Pagamento com pix' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/loja/:feature/:productId/comprar/:idPurchase/obrigado',
    name: 'PaymentThankYou',
    component: () => import(/* webpackChunkName: "payment-thank-you" */ '../pages/Shopping/pages/ThankYouPayment/index.vue'),
    meta: { title: 'Obrigado pelo pagamento' },
    beforeEnter: permissionShopping,
  },
  {
    path: '/plano/renovacao',
    name: 'RenewPlan',
    component: () => import(/* webpackChunkName: "renew-plan" */ '../pages/RenewPlan/RenewPlan.vue'),
    meta: { title: 'Renovação do plano' },
  },
  {
    path: '/plano/pendencias',
    name: 'RenewPendencies',
    component: () => import(/* webpackChunkName: "renew-pendencies" */ '../pages/RenewPlan/pages/PendencyDetail/PendencyDetail.vue'),
    meta: { title: 'Pendências de renovação' },
  },
  {
    path: '/plano/renovacao/:idPurchase/pix',
    name: 'RenewPaymentPix',
    component: () => import(/* webpackChunkName: "renew-payment-pix" */ '../pages/RenewPlan/pages/PaymentPix/PaymentPix.vue'),
    meta: { title: 'Pagamento com pix' },
  },
  {
    path: '/plano/renovacao/:idPurchase/boleto',
    name: 'RenewPaymentBillet',
    component: () => import(/* webpackChunkName: "renew-payment-billet" */ '../pages/RenewPlan/pages/PaymentBillet/PaymentBillet.vue'),
    meta: { title: 'Pagamento com boleto' },
  },
  {
    path: '/plano/renovacao/:idPurchase/obrigado',
    name: 'RenewPaymentThankYou',
    component: () => import(/* webpackChunkName: "renew-payment-thank-you" */ '../pages/RenewPlan/pages/ThankYouPayment/index.vue'),
    meta: { title: 'Obrigado pelo pagamento' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    const routesFromTo = { to, from };
    store.commit('RouterHistory/getRoutesFromTo', routesFromTo);
  },
});

function getPath() {
  // @ts-ignore
  return router.history.current;
}

function getQuery() {
  // @ts-ignore
  return router.history.current.query;
}

function nextPage(to: Route, from: Route, next: NavigationGuardNext) {
  const currentPath = getPath().path;
  const currentFileID = getQuery().fileID;
  if (currentPath === to.path && currentFileID === to.query?.fileID && currentPath) {
    return;
  }
  if (to.name) next();
}

function redirectToRouteWithIsFreeQuery(to: Route) {
  router.push({
    path: to.path,
    query: {
      isFree: 'true',
    },
  });
}

router.afterEach((to) => {
  document.title = `Explicaê - ${to.meta?.title}`;

  const myClass = window.document.getElementById('top-0') as HTMLElement;
  if (myClass) myClass.scrollIntoView();

  InstanceAmplitude.setPageview({
    page: window.location.href,
    title: to?.name || '',
  });
});

function setPageMaintenance() {
  router.push({
    name: 'Maintenance',
  });
}

function setPageProblem() {
  router.push({
    name: 'Problem',
  });
}

function setSidebar(nameRoute: string | null | undefined) {
  if (nameRoute !== 'PlannerConfig') {
    store.commit('setSidebar', false);
    store.commit('setSidebarExistence', false);
    store.commit('setDecreaseSidebar', false);
  }
}

function preventFromLeavingRoute(to: Route) {
  const setModal = new ActiveModal();
  setModal.activeModal('ModalExitSimulated');
  store.commit('setPendingRoute', to.path);
}

let profile: any;
let validate: any;

router.beforeEach(async (to, from, next) => {
  if (
    from.name === 'SimulatedQuestions'
    && !store.getters.clickedCloseModalExitSimulated
    && !store.getters.simulatedSent
  ) {
    preventFromLeavingRoute(to);
    return;
  }

  topbar.show();
  setSidebar(to.name);
  store.commit('setPendingRoute', to.path);
  store.commit('setClickedCloseModalExitSimulated', false);

  if (JSON.parse(process.env.VUE_APP_MAINTENANCE) && to.name !== 'Maintenance') {
    setPageMaintenance();

    return;
  }

  const isAnonymousPage = to.meta?.isAnonymous;

  const token = to.query?.token;
  const isFree = to.query?.isFree;

  if (token) {
    store.commit('setToken', String(token));

    if (isFree) {
      redirectToRouteWithIsFreeQuery(to);
      return;
    }

    await router.push(to.path || '/');

    return;

  } if (store.getters.token === '') {
    store.commit('initialState');
  }

  const AccountService = new Account();

  try {
    if (!validate) validate = await AccountService.validate();

  } catch (error) {
    CookieUtil.removeAccess();

    if (error?.status === STATUS_RESPONSE_API.SERVER_ERROR_INTERNAL_ERROR) {
      setPageProblem();
      return;
    }

    profile = null;
    logout({ isAnonymousPage });

    if (isAnonymousPage) {
      assessBusinessModelForTheme({});

      nextPage(to, from, next);
    }
  }

  if (to.name === null) {
    if (getPath().path !== '/404') await router.push('/404');

    topbar.hide();
    return;
  }

  if (!store.getters.appStart) {
    if (!validate) {
      profile = null;
      logout({ isAnonymousPage });
      topbar.hide();

      return;
    }

    if (!store.getters.profile) {
      profile = null;
      logout({ isAnonymousPage });
      topbar.hide();

      return;
    }

    store.commit('setAppStart', true);
  }

  if (validate) {
    if (!profile) {
      try {
        profile = await updateProfile();
      } catch (error) {
        logout({ isAnonymousPage });
        topbar.hide();

        return;
      }
      // Aplicando o indentificador do usuário na instancia do Amplitude
      InstanceAmplitude.identify({
        user: profile,
      });
    }

    topbar.hide();

    nextPage(to, from, next);
  } else {
    profile = null;
    logout({ isAnonymousPage });
  }

  removeIsFreeQueryFromUrl(to);
});

export default router;
